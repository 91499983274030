import React, { useState } from "react";
import EnquiryForm from "../EnquiryForm";
import styled, { keyframes } from "styled-components";
import { useLocation } from "react-router-dom";

const RentMeModal = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(255, 255, 255, 0.9);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 9999999999;
`;

const HeaderSection = styled.header`
  background-color: #90caf9;
  padding: 20px;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const Title = styled.h1`
  font-size: 24px;
  color: #333;
`;

const RentMeButton = styled.button`
  position: fixed;
  top: 20px;
  right: 20px;
  padding: 10px 20px;
  background-color: #333;
  color: #fff;
  font-size: 16px;
  text-decoration: none;
  border: none;
  cursor: pointer;
`;

const runAnimation = keyframes`
  0% { transform: translateX(100%); }
  100% { transform: translateX(-100%); }
`;

const MessageRibbonContainer = styled.div`
  position: fixed;
  bottom: 0; /* Move the ribbon to the bottom of the screen */
  width: 100%;
  overflow: hidden; /* Hide the overflow to create a seamless effect */
  background-color: rgba(128, 128, 128, 0.8); /* Grey color */
  z-index: 10000000000;
`;

const MessageRibbon = styled.div`
  display: inline-block;
  padding: 10px 20px;
  white-space: nowrap; /* Prevent text from wrapping */
  font-size: 20px;
  color: red; /* Set the text color to red */
  animation: ${runAnimation} var(--animation-duration) linear infinite;

  /* Set default animation speed for desktop */
  --animation-duration: 20s;

  /* Adjust animation speed for mobile */
  @media (max-width: 768px) {
    --animation-duration: 40s; /* Slower animation for mobile */
  }
`;

const BusinessHoursSection = styled.div`
  margin: 20px 0;
  padding: 20px;
  background-color: #f0f0f0;
  border-radius: 8px;
`;


const BusinessContact = styled.div`
  margin: 30px 0;
  padding: 30px;
  background-color: #f0f0f0;
  border-radius: 15px;
`;


const Header = () => {
  const { pathname } = useLocation();
  const [showCustForm, setShowCustForm] = useState(false);

  const handleRentMeClick = () => {
    setShowCustForm(true);
  };

  const handleCloseModal = () => {
    setShowCustForm(false);
  };

  return (
    <>
      {pathname === "/" && (
        <>
          <HeaderSection>
            <Title>MoreSpaceUp</Title>
            {pathname === "/" && (
              <RentMeButton onClick={handleRentMeClick}>Rent Me</RentMeButton>
            )}
          </HeaderSection>

                  <MessageRibbonContainer>
            <MessageRibbon>
              Secure your space in under one minute. $60 for 10X10 room.
            </MessageRibbon>
          </MessageRibbonContainer>

          {showCustForm && (
            <RentMeModal>
              <EnquiryForm />
              <button onClick={handleCloseModal}>Close</button>
            </RentMeModal>
          )}
        </>
      )}
    </>
  );
};

export default Header;
